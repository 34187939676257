import "core-js/modules/es.array.push.js";
import houseItem2 from '@/components/houseItem2.vue';
export default {
  components: {
    houseItem2
  },
  data() {
    return {
      search_name: '',
      //搜索关键词
      searchList: [],
      //搜索列表

      total: 8,
      //总条数
      currentPage: 1,
      //当前页数
      pageSize: 8,
      //每页的条数
      pageSizes: [8, 10, 20, 30, 40, 50, 100]
    };
  },
  watch: {
    // 监听路由参数的变化
    $route() {
      this.search_name = this.$route.query.search_name;
      this.getSearchResult();
    }
  },
  mounted() {
    this.search_name = this.$route.query.search_name;
    this.getSearchResult();
  },
  methods: {
    backClick() {
      this.$router.push('/home');
    },
    //获取搜索列表
    getSearchResult() {
      let data = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        search_name: this.search_name
        // user_id: this.user_id,
      };

      this.$http.searchResult(data).then(res => {
        if (res.code == 1) {
          this.searchList = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    currentChangeClick(currentPage) {
      this.currentPage = currentPage;
      this.getSearchResult();
    },
    sizeChangeClick(pageSize) {
      this.pageSize = pageSize;
      this.getSearchResult();
    }
  }
};